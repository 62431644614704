<template>
  <ElConfigProvider :locale="elementPlusLanguage">
    <ElContainer id="app" class="layout-container-demo h-screen">
      <ElHeader class="!align-bottom navbar-top">
        <FullLayoutNavbarTop
          :key="customNavbarKey"
          :drawer="drawer"
          :show-drawer-button="showDrawerButton()"
          @click="onClick"
          @showAvatarDialog="handleAvatarDialog()"
          :module-selected="moduleSelected"
          @setModule="setModule"
          :user="user"
          @setSearchDrawer="searchDrawer = true"
          @setDrawer="drawer = !drawer"
          @showPauseDialog="showPauseDialog = true"
        />
      </ElHeader>
      <ElContainer class="w-full">
        <ElAside
          :style="
            containerWidth <= 1100
              ? 'width: 0px'
              : showDrawer()
              ? !drawer
                ? 'width: 64px;'
                : 'width: 234px;'
              : 'width: 0px;'
          "
          class="custom-aside"
        >
          <FullLayoutVerticalSidebar
            :module-selected="moduleSelected"
            @openModuleDropdown="handleModuleDialog()"
            :drawer="drawer"
            @setDrawer="drawer = !drawer"
            @changePath="changePath"
          />
        </ElAside>
        <ElMain
          :key="customKey"
          :class="`${getToolbarCollapsedClass()} ${getMainClass()}`"
        >
          <NuxtPage />
          <div id="recaptcha-container"></div>
          <el-dialog
            v-model="showAuthUpdatePasswordForm"
            :width="containerWidth <= 600 ? '100%' : '400px'"
          >
            <template #header>
              <span class="dashboard-title">
                {{ $t('update_password') }}
              </span>
            </template>
            <AuthUpdatePasswordForm
              @cancelUpdatePasswordForm="updatePassword"
              @submitUpdatePasswordForm="updatePassword"
            />
          </el-dialog>
          <el-dialog
            v-model="showUpdatePasswordDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :width="containerWidth <= 600 ? '100%' : '400px'"
          >
            <template #header>
              <span class="dashboard-title">
                {{ $t('update_password') }}
              </span>
            </template>
            <AuthUpdatePasswordForm
              @cancelUpdatePasswordForm="showUpdatePasswordDialog = false"
              @submitUpdatePasswordForm="showUpdatePasswordDialog = false"
            />
          </el-dialog>
          <el-dialog
            v-model="showMinimumProfileForm"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :width="containerWidth <= 600 ? '100%' : '400px'"
          >
            <template #header>
              <span class="dashboard-title">
                {{ $t('update_profile') }}
              </span>
            </template>
            <ProfileUpdateMinimumProfileForm
              @cancelUpdateProfileForm="updateProfile"
              @submitUpdateProfileForm="updateProfile"
            />
          </el-dialog>
          <UtilsDrawersCustomDrawer
            :show-close="true"
            :show-back="false"
            class-name="avatar-drawer"
            :drawer="showAvatarDialog"
            @close="showAvatarDialog = false"
          >
            <FullLayoutAvatarDialog :user="user" @click="onClick" />
          </UtilsDrawersCustomDrawer>
          <UtilsDrawersCustomDrawer
            v-if="width <= 1100"
            :show-close="true"
            :show-back="false"
            :title="$t('more')"
            class-name="modules-drawer"
            :drawer="drawer"
            @close="drawer = false"
          >
            <FullLayoutModuleSelector @setModule="setModule" />
          </UtilsDrawersCustomDrawer>
          <el-drawer
            v-if="width <= 1100"
            ref="drawerRef"
            :model-value="searchDrawer"
            direction="ttb"
            :close-on-press-escape="true"
            :show-close="true"
            @close="searchDrawer = false"
            modal-class="search-drawer-overlay"
            class="search-drawer"
          >
            <template #header>
              <FullLayoutHeader v-if="width <= 1100" />
              <Icons
                name="24px/close"
                class="icon-close"
                @click="searchDrawer = false"
              />
            </template>
          </el-drawer>
          <el-dialog
            v-model="showPauseDialog"
            :width="containerWidth <= 600 ? '100%' : '400px'"
            @close="showPauseDialog = false"
          >
            <template #header>
              <span class="dashboard-title">
                {{ $t('pause_reason') }}
              </span>
            </template>
            <FullLayoutPauseDialog
              :employee="employeeSelected"
              :signing-reasons="signingReasons"
              :signing="signingsToday[signingsToday.length - 1]"
              @update="
                ;(showPauseDialog = false),
                  $eventBus.$emit('signingNavbarRefetch')
              "
              @cancel="showPauseDialog = false"
            />
          </el-dialog>
        </ElMain>
      </ElContainer>
    </ElContainer>
  </ElConfigProvider>
</template>

<script>
import LOGOUT from '~/graphql/auth/mutation/logout.gql'
import ME from '@/graphql/user/query/me.gql'
import UPDATE_LOCALE from '~/graphql/user/mutation/updateLocale.gql'
import verticalSidebarItemsBackoffice from '~/models/vertical-sidebar-items-backoffice'
import verticalSidebarItemsGuide from '~/models/vertical-sidebar-items-guide'
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useFavicon } from '@vueuse/core'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'
import fr from 'element-plus/es/locale/lang/fr'
import de from 'element-plus/es/locale/lang/de'
import sv from 'element-plus/es/locale/lang/sv'
import ar from 'element-plus/es/locale/lang/ar'
import dayjs from 'dayjs'
dayjs.Ls.en.weekStart = 1

const ACTIONS = {
  LOGOUT: 'logout',
  SET_LOCALE: 'set_locale',
  UPDATE_PASSWORD: 'update_password',
  GO_SETTINGS: 'go_settings'
}

export default defineComponent({
  setup() {
    const user = getAuthUser()
    const router = useRouter()
    const route = useRoute()
    const roles = getAuthRoles()
    const { width } = useBreakpoints()
    const { $eventBus } = useNuxtApp()
    const { locale } = useI18n({ useScope: 'global' })
    locale.value = user?.value?.language?.toLowerCase() ?? 'en'
    return {
      router,
      route,
      user,
      locale,
      width,
      roles,
      $eventBus
    }
  },
  data() {
    return {
      drawer: false,
      containerWidth: 0,
      containerHeight: 0,
      origContainerWidth: window.innerWidth,
      sidebarWidth: '300px',
      showAuthUpdatePasswordForm: false,
      showMinimumProfileForm: false,
      showAvatarDialog: false,
      showUpdatePasswordDialog: false,
      moduleSelected: 'home',
      drawer: false,
      searchDrawer: false,
      customKey: 0,
      customNavbarKey: 0,
      elementPlusLanguage: null,
      newColorScheme: 'light',
      showPauseDialog: false,
      signingReasons: null,
      signingsToday: null,
      employeeSelected: null
    }
  },
  watch: {
    containerWidth(newVal) {
      if (this.origContainerWidth !== newVal) {
        if (this.origContainerWidth <= 1100 && newVal > 1100) {
          this.customKey = Math.round(Math.random() * 1000)
          this.moduleDialog = false
          this.avatarDialog = false
          this.drawer = true
        }
        if (this.origContainerWidth > 1100 && newVal <= 1100) {
          this.customKey = Math.round(Math.random() * 1000)
          this.moduleDialog = false
          this.avatarDialog = false
          this.drawer = false
        }
      }
      this.origContainerWidth = newVal
    },
    drawer(newVal) {
      if (!newVal) {
        this.getModuleSelected()
      }
    },
    '$route.fullPath'(newVal) {
      const groups = newVal === '/' ? ['/', '/'] : newVal.match(/\/[^/]+/g)
      const item = this.modules
        .map((group) => {
          if (
            group.children &&
            group.children.find((i) => '/' + i.to === groups[1])
          ) {
            return group
          } else {
            return null
          }
        })
        .find((i) => i !== null)
      this.moduleSelected = item?.title ?? 'home'
      this.saveRecentPage(this.moduleSelected)
      this.showPasswordForm()
    },
    newColorScheme() {
      useFavicon(this.favicon, {
        rel: 'icon'
      })
    }
  },
  computed: {
    modules() {
      let verticalSidebarItems = verticalSidebarItemsBackoffice
      if (this.roles?.find((i) => i.name === 'Guide')) {
        verticalSidebarItems = verticalSidebarItemsGuide
      }
      return verticalSidebarItems.filter(
        (item) =>
          !item.roles ||
          item.roles.length === 0 ||
          (item.roles && item.roles.length)
      )
    },
    favicon() {
      return this.newColorScheme === 'dark'
        ? '/favicon.svg'
        : '/favicon-dark.svg'
    },
    isPasswordExpired() {
      const passwordChangedAt = this.user ? this.user.password_changed_at : null

      if (this.userHasToChangePassword(this.user, passwordChangedAt)) {
        return true
      }
      return false
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.getContainerSize()
      if (this.containerWidth > 1100) {
        this.drawer = true
      } else {
        this.drawer = false
      }
    })
    if (!this.user?.first_name || !this.user?.last_name) {
      this.showMinimumProfileForm = true
    }
    this.getInitialModuleSelected()
    this.setDrawer()
    this.setElementPlusLocale()
    this.setColorScheme()
    this.$eventBus.$on('showPauseDialog', ($event) => {
      this.showPauseDialog = true
      this.signingsToday = $event.signingsToday
      this.signingReasons = $event.signingReasons
      this.employeeSelected = $event.value
    })
  },
  created() {
    window.addEventListener('resize', this.onResize)
    this.setColorScheme()
    this.showPasswordForm()
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.getContainerSize()
      if (this.containerWidth > 1100) {
        this.drawer = true
      } else {
        this.drawer = false
      }
      this.showAvatarDialog = false
    },
    getContainerSize() {
      this.containerWidth = document.body.clientWidth
    },
    async onClick(e) {
      switch (e.action) {
        case ACTIONS.LOGOUT:
          await this.onLogout()
          break
        case ACTIONS.UPDATE_PASSWORD:
          this.updatePassword()
          break
        case ACTIONS.SET_LOCALE:
          await this.setLocale(e.value)
          break
        case ACTIONS.GO_SETTINGS:
          this.router.push('/configuration/users')
          this.showAvatarDialog = false
          break
      }
    },
    async onLogout() {
      try {
        await mutation(LOGOUT)
        logout()
      } catch (e) {
        this.$showError(e)
        console.error(e)
      }
    },
    updatePassword() {
      this.showAuthUpdatePasswordForm = !this.showAuthUpdatePasswordForm
    },
    async updateProfile() {
      this.showMinimumProfileForm = !this.showMinimumProfileForm
      const res = await mutation(ME)

      await getAuthUser(res?.result?.data?.me ?? null)
      this.user = res?.result?.data?.me ?? null
    },
    async setLocale(locale) {
      try {
        const variables = {
          uuid: this.user.uuid,
          language: locale
        }

        await mutation(UPDATE_LOCALE, variables)
        this.locale = locale.toLowerCase()
        this.$i18n.locale = locale.toLowerCase()
        this.customNavbarKey = setCustomKeyVariable()
        this.user.language = locale
        this.showAvatarDialog = false
      } catch (e) {
        this.$showError(e)
        console.error(e)
      }
    },
    setElementPlusLocale() {
      const locale = this.$i18n.locale
      switch (locale.toLowerCase()) {
        case 'es':
          this.elementPlusLanguage = es
          break
        case 'en':
          this.elementPlusLanguage = en
          break
        case 'fr':
          this.elementPlusLanguage = fr
          break
        case 'de':
          this.elementPlusLanguage = de
          break
        case 'sv':
          this.elementPlusLanguage = sv
          break
        case 'ar':
          this.elementPlusLanguage = ar
          break
        default:
          this.elementPlusLanguage = en
          break
      }
    },
    smsSent() {
      this.isSmsSent = true
    },
    getModuleSelected() {
      const groups =
        this.$route.fullPath === '/'
          ? ['/', '/']
          : this.$route.fullPath.match(/\/[^/]+/g)
      const item = this.modules
        .map((group) => {
          if (
            group.children &&
            group.children.find((i) => '/' + i.to === groups[1])
          ) {
            return group
          } else {
            return null
          }
        })
        .find((i) => i !== null)
      this.moduleSelected = item?.title ?? 'home'
    },
    getInitialModuleSelected() {
      const groups =
        this.$route.fullPath === '/'
          ? ['/', '/']
          : this.$route.fullPath.match(/\/[^/]+/g)
      const item = this.modules
        .map((group) => {
          if (
            group.children &&
            group.children.find((i) => '/' + i.to === groups[1])
          ) {
            return group
          } else {
            return null
          }
        })
        .find((i) => i !== null)
      this.moduleSelected = item?.title ?? 'home'
    },
    setModule(event) {
      this.moduleSelected = event
      this.changePath()
    },
    changePath() {
      if (this.containerWidth <= 1100) {
        this.getModuleSelected()
        this.drawer = false
      }
    },
    showDrawer() {
      if (this.containerWidth > 1100) {
        if (this.route.fullPath === '/design') {
          return false
        }
        const moduleSelected = this.modules.find(
          (i) => i.title === this.moduleSelected
        )
        if (
          moduleSelected &&
          (!moduleSelected.children || moduleSelected.children.length === 0)
        ) {
          return false
        }
        return true
      } else {
        if (this.showAvatarDialog) {
          return false
        }
        return true
      }
    },
    showDrawerButton() {
      if (this.containerWidth > 1100) {
        if (this.route.fullPath === '/design') {
          return false
        }
        const moduleSelected = this.modules.find(
          (i) => i.title === this.moduleSelected
        )
        if (
          moduleSelected &&
          (!moduleSelected.children || moduleSelected.children.length === 0)
        ) {
          return false
        }
        return true
      }
      return true
    },
    setDrawer() {
      if (this.containerWidth > 1100) {
        this.drawer = true
      } else {
        this.drawer = false
      }
    },
    goHome() {
      this.router.push('/')
      this.drawer = false
      this.showAvatarDialog = false
      this.moduleSelected = 'home'
    },
    handleAvatarDialog() {
      this.showAvatarDialog = true
    },
    handleModuleDialog() {
      if (this.containerWidth <= 1100) {
        this.drawer = false
      }
    },
    getToolbarCollapsedClass() {
      if (!this.drawer) {
        return 'toolbar-collapse'
      }
      if (!this.showDrawer()) {
        return 'main-not-shown'
      }
      return ''
    },
    getMainClass() {
      if (this.containerWidth > 1100) {
        if (this.showDrawer()) {
          if (this.drawer === false) {
            return 'main-collapsed'
          }
          if (this.drawer === true) {
            return 'main-expanded'
          }
        }
        if (!this.showDrawer()) {
          return 'main-not-shown'
        }
        return ''
      }
      return ''
    },
    saveRecentPage(moduleSelected) {
      if (
        this.$route?.name &&
        !this.$route?.matched[0]?.path.includes(':id()') &&
        !this.$route?.name.includes('login')
      ) {
        const path = this.$route.fullPath.split('/').filter((i) => i)
        let recentPages = []
        if (localStorage.getItem('recentPages')) {
          recentPages = JSON.parse(localStorage.getItem('recentPages'))
        }
        if (
          path?.length &&
          !recentPages.find((i) => i.fullPath === this.$route.fullPath)
        ) {
          recentPages.push({
            fullPath: this.$route.fullPath,
            name: path.map((i) => this.$t(i.replaceAll('-', '_'))).join(' / '),
            module: moduleSelected
          })
          localStorage.setItem('recentPages', JSON.stringify(recentPages))
        }
        let topModules = []
        if (localStorage.getItem('topModules')) {
          topModules = JSON.parse(localStorage.getItem('topModules'))
        }
        if (
          moduleSelected !== 'home' &&
          !topModules.find((i) => i.module === moduleSelected)
        ) {
          topModules.push({
            module: moduleSelected,
            numVisited: 1
          })
        } else if (moduleSelected !== 'home') {
          topModules.find((i) => i.module === moduleSelected).numVisited++
        }
        localStorage.setItem('topModules', JSON.stringify(topModules))
      }
    },
    setColorScheme() {
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        this.newColorScheme = 'dark'
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          this.newColorScheme = event.matches ? 'dark' : 'light'
        })
    },
    showPasswordForm() {
      if (this.isPasswordExpired) {
        this.showUpdatePasswordDialog = true
      } else {
        this.showUpdatePasswordDialog = false
      }
    },
    userHasToChangePassword(user, passwordChangedAt) {
      return user && !passwordChangedAt
    }
  }
})
</script>

<style scoped lang="scss">
.navbar-top {
  border-bottom: 1px solid #f4f4f4;
  width: 100%;
  position: fixed;
  z-index: 8;
  top: 0;
  background-color: var(--brand-white);
  @media (min-width: 1100px) {
    height: 64px;
  }
  @media (max-width: 1100px) {
    height: 66px;
  }
}

.custom-aside {
  position: fixed;
  @media (min-width: 1100px) {
    top: 64px;
    width: 234px;
  }
  @media (max-width: 1100px) {
    top: 66px;
  }
  z-index: 8;
  height: 100%;
  background-color: var(--brand-white) !important;
  transition: all 0.2s linear !important;
  border-right: 1px solid var(--brand-off-white);
}

:deep(.el-overlay.custom-drawer-overlay),
:deep(.el-overlay.filter-drawer-overlay),
:deep(.el-overlay.search-drawer-overlay) {
  background-color: rgb(0, 0, 0, 0) !important;
  @media (min-width: 1100px) {
    left: calc(100vw - 397px);
  }
}

@media (max-width: 1100px) {
  :deep(.avatar-drawer) {
    .el-drawer__header {
      padding: 0 !important;
      .icon-close {
        position: relative;
        top: 24px;
        right: 24px;
      }
    }
  }
}
</style>
